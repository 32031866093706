<template>
	<div>
		<div :class="{ active: isStickyShortDesc }" class="sticky-desc grey lighten-4">
			<v-container style="max-width: 85rem">
				<div class="d-flex flex-row justify-space-between" v-if="$vuetify.breakpoint.mdAndUp">
					<div class="d-flex flex-column justify-space-around">
						<div class="text-h6">
							{{ title(mappedCar) }}
							<v-tooltip bottom v-if="carDetails.hasAdvantaged">
								<template v-slot:activator="{ on, attrs }">
									<v-icon right color="template-color" dark v-bind="attrs" v-on="on"> fas fa-star</v-icon>
								</template>
								<span>Fırsat aracı</span>
							</v-tooltip>
						</div>
						<div class="d-flex flex-row">
							<div class="font-weight-bold mr-3" v-if="mappedCar.plate">
								{{ mappedCar.plate }}
							</div>
							<div
								v-for="(feature, featureIndex) in mappedCar.features"
								:key="`sticky-feature-${featureIndex}`"
								class="mr-3"
							>
								<div class="feature-text">
									<v-icon left size="14" color="template-color darken-3" class="feature-icon">{{
										feature.icon
									}}</v-icon>
									{{ feature.value }}
								</div>
							</div>
						</div>
					</div>
					<div class="flex-column text-right">
						<div class="text-h6 font-weight-regular text-decoration-line-through grey--text" v-if="mappedCar.listPrice">
							{{ mappedCar.listPrice | tl({ avoidEmptyDecimals: '' }) }}
						</div>
						<div class="text-h4 font-weight-bold">
							{{ mappedCar.salesPrice | tl({ avoidEmptyDecimals: '' }) }}
							<div
								class="d-flex align-center text-left font-weight-regular"
								style="font-size: 13px; line-height: 13px"
								v-if="minCreditPerMonth"
							>
								ayda
								{{ minCreditPerMonth | tl({ fractionCount: 0, avoidEmptyDecimals: '', symbolPosition: 'back' }) }}'den
								başlayan taksitlerle..
							</div>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="d-flex flex-column">
						<div class="d-flex flex-row justify-space-between font-weight-bold">
							<div>{{ mappedCar.brand }} {{ mappedCar.model }}</div>

							<div class="text-right">
								{{ mappedCar.salesPrice | tl({ avoidEmptyDecimals: '' }) }}
								<div
									class="d-flex align-center text-left font-weight-regular"
									style="font-size: 12px; line-height: 13px"
									v-if="minCreditPerMonth"
								></div>
							</div>
						</div>
						<div class="d-flex flex-row justify-space-between">
							<div class="d-flex flex-row">
								<div
									class="mobile-feature"
									v-for="(feature, featureIndex) in mappedCar.features"
									:key="`sticky-feature-mobile-${featureIndex}`"
								>
									{{ feature.value }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</v-container>
			<div class="grey lighten-3">
				<v-container style="max-width: 85rem; position: relative" class="d-flex flex-row">
					<filter-bread-crumb :car-details="mappedCar" />
					<v-spacer></v-spacer>
					<div>
						<social-share :details="carDetails" />
						<v-btn
							class="ml-2"
							depressed
							icon
							:width="$vuetify.breakpoint.mobile ? 24 : 42"
							:height="$vuetify.breakpoint.mobile ? 24 : 42"
							color="transparent"
							@click.prevent="addToFavorites(mappedCar.id)"
						>
							<v-icon
								:color="isFavorite(mappedCar.id) ? 'red ' : 'grey lighten-1'"
								:size="$vuetify.breakpoint.mobile ? 20 : 26"
								>fas fa-heart</v-icon
							>
						</v-btn>
					</div>
				</v-container>
			</div>
		</div>

		<div class="fill-height">
			<v-card tile light flat class="fill-height information-card">
				<div class="list-id">#{{ mappedCar.listingId }}</div>
				<v-card-title class="info-car-title text-h6 text-md-h5 font-weight-bold">
					{{ mappedCar.brand }} {{ mappedCar.model }} {{ mappedCar.version }}
				</v-card-title>
				<v-card-subtitle>
					<span class="car-plate">{{ mappedCar.plate }}</span>
					<v-row dense no-gutters class="mt-5">
						<template v-for="(feature, featureIndex) in mappedCar.features">
							<v-col
								class="d-flex flex-column align-center feature-text"
								:key="`feature-${featureIndex}`"
								v-if="feature.show"
							>
								<v-icon :small="$vuetify.breakpoint.smAndDown" color="template-color darken-3" class="feature-icon">
									{{ feature.icon }}</v-icon
								>
								{{ feature.value }}
							</v-col>
						</template>
					</v-row>
				</v-card-subtitle>
				<v-divider></v-divider>
				<v-card-text class="information-text" ref="contactDetails">
					<v-row no-gutters>
						<v-col d-flex align-start flex-column justify-start>
							<div class="d-flex flex-row align-center mt-5" v-if="dealer.name">
								<v-icon color="template-color darken-3" small left>fas fa-building</v-icon>
								{{ dealer.name }}
							</div>
							<div class="d-flex flex-row align-center mt-5" v-if="dealer.address">
								<v-icon color="template-color darken-3" small left>fas fa-map-marker-alt</v-icon>
								{{ dealer.address }}
							</div>
							<div class="d-flex flex-row align-center mt-5" v-if="dealer.contactName">
								<v-icon color="template-color darken-3" small left>fas fa-user</v-icon>
								<div class="font-weight-bold grey--text text--darken-4">
									{{ dealer.contactName }}
								</div>
							</div>
							<div class="d-flex flex-row align-center mt-5" v-if="dealer.contactPhone">
								<v-icon color="template-color darken-3" small left>fas fa-phone-alt</v-icon>
								<a :href="`tel:${dealer.contactPhone}`" class="font-weight-bold grey--text text--darken-4">
									{{ dealer.contactPhone | VMask('## (###) ### ## ##') }}
								</a>
							</div>
							<div class="text-right mt-5">
								<div class="text-h6 text-decoration-line-through" v-if="mappedCar.listPrice">
									{{ mappedCar.listPrice | tl({ avoidEmptyDecimals: '' }) }}
								</div>
								<div
									class="d-flex font-weight-bold text-h5 text-md-h4"
									:class="minCreditPerMonth ? 'justify-space-between' : 'justify-end'"
								>
									<div
										v-if="minCreditPerMonth"
										class="d-flex align-center text-start font-weight-regular"
										style="max-width: 200px; font-size: 13px; line-height: 13px"
									>
										ayda
										{{
											minCreditPerMonth | tl({ fractionCount: 0, avoidEmptyDecimals: '', symbolPosition: 'back' })
										}}'den başlayan taksitlerle..
									</div>
									<div class="text-no-wrap">
										{{ mappedCar.salesPrice | tl({ avoidEmptyDecimals: '' }) }}
									</div>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>

<script>
import carFavoriteMixin from '../../_mixins/carFavoriteMixin';
import carFeatureMixin from '../../_mixins/carFeatureMixin';
import filterBreadCrumb from './filterBreadCrumb.vue';
import SocialShare from './socialShare.vue';

export default {
	components: { filterBreadCrumb, SocialShare },
	name: 'Information',
	mixins: [carFeatureMixin, carFavoriteMixin],
	props: {
		carDetails: {
			type: Object,
			default: null,
			required: true,
		},
	},
	data() {
		return {
			stickyContact: false,
		};
	},
	computed: {
		mappedCar() {
			return {
				...this.carDetails,
				features: this.mapFeatures(this.carDetails),
			};
		},
		dealer() {
			return this.mappedCar.dealer;
		},
		isStickyShortDesc() {
			return this.stickyContact;
		},
		minCreditPerMonth() {
			if (this.showCredit && this.mappedCar.creditInformation.minCreditPerMonth > 0) {
				return this.mappedCar.creditInformation.minCreditPerMonth;
			}
			return null;
		},
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
	},
	created() {
		this.$nextTick(() => {
			window.addEventListener('scroll', this.onScroll);
			this.setStickyContact();
		});
	},
	methods: {
		setStickyContact() {
			const headerHeight = document.querySelector('.top-bar').offsetHeight;
			const detailsTab = document.querySelector('.card-shadow ').offsetTop;

			const top = this.$vuetify.breakpoint.mdAndUp
				? this.$refs.contactDetails?.offsetTop + this.$refs.contactDetails?.offsetHeight + headerHeight
				: detailsTab;

			if (window.scrollY > top) {
				this.stickyContact = true;
			} else {
				this.stickyContact = false;
			}
		},
		onScroll() {
			this.setStickyContact();
		},
	},
};
</script>

<style lang="scss" scoped>
.information-card {
	background: rgb(245, 245, 245);
	background: linear-gradient(0deg, rgb(248, 248, 248) 0%, rgb(240, 240, 240) 100%);
	box-shadow: 10px 0px 20px -5px rgb(233, 233, 233) !important;

	.info-car-title {
		word-break: keep-all;
	}

	.feature-icon {
		margin-bottom: 10px;
	}

	.feature-text {
		color: #333 !important;
		font-weight: 600 !important;
		font-size: 1rem !important;
		&--mobile {
			font-size: 0.9rem !important;
		}
	}

	.information-text {
		color: #333;
		font-size: 1rem;
	}

	.car-plate {
		font-size: 1rem !important;
	}

	.list-id {
		position: absolute;
		top: -20px;
		right: 0px;
		background: #f0f0f0;
		padding: 1px 5px;
		font-weight: bold;
		border-radius: 5px 0px;
		font-size: 12px;
	}
}
.sticky-desc {
	.mobile-feature {
		&:after {
			content: '·';
			margin: 0px 5px 0px 2px;
		}

		&:last-child {
			&:after {
				content: '' !important;
			}
		}
	}

	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibillity 0.3s;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	z-index: 9999;
	background: var(--v-secondary-lighten1);
	background: linear-gradient(0deg, rgb(236, 236, 236) 0%, rgb(250, 250, 250) 100%);
	box-shadow: 0px 1px 1px 0px rgb(224, 224, 224) !important;
	opacity: 0;
	transform: translateY(-100%);
	visibility: hidden;

	&.active {
		visibility: visible;
		opacity: 1;
		transform: translateY(0px);
	}
}
</style>
