<template>
	<v-dialog v-model="dialog" persistent max-width="600px" light>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				:class="{ 'get--information': !$vuetify.breakpoint.mobile }"
				:block="$vuetify.breakpoint.mobile"
				depressed
				class="font-weight-bold template-text--text"
				color="template-color"
				dark
				v-bind="attrs"
				v-on="on"
			>
				<v-icon left>fa-circle-question</v-icon> BİLGİ AL
			</v-btn>
		</template>
		<transition name="fade" mode="out-in">
			<v-card v-if="!isSent" key="contactForm">
				<v-card-title>
					<span class="text-h5">Bilgi Al</span>
				</v-card-title>
				<v-card-text>
					<v-container class="pa-0">
						<v-row dense>
							<v-col cols="12">
								<v-text-field
									hide-details="auto"
									placeholder="Adınız ve Soyadınız"
									color="template-color"
									v-model="$v.contactForm.name.$model"
									:error-messages="nameErrors()"
								>
								</v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field-simplemask
									v-model="$v.contactForm.phone.$model"
									v-bind:properties="{
										placeholder: '',
										inputmode: 'numeric',
										hideDetails: 'auto',
										placeholder: '0(555) 555-5555',
										color: 'template-color',
										errorMessages: phoneErrors(),
									}"
									v-bind:options="{
										inputMask: '#(###) ###-##-##',
										outputMask: '###########',
										empty: null,
										applyAfter: false,
										alphanumeric: false,
									}"
								>
								</v-text-field-simplemask>
							</v-col>
							<v-col cols="12">
								<v-text-field
									hide-details="auto"
									placeholder="mail@siteadi.com"
									color="template-color"
									v-model="$v.contactForm.email.$model"
									:error-messages="emailErrors()"
								>
								</v-text-field>
							</v-col>
							<v-col cols="12">
								<v-textarea
									no-resize
									hide-details="auto"
									placeholder="Mesajınız"
									color="template-color"
									v-model="$v.contactForm.message.$model"
									:error-messages="messageErrors()"
								>
								</v-textarea>
							</v-col>
							<v-col cols="12">
								<v-checkbox
									color="template-color darken-2"
									dense
									hide-details="auto"
									:error-messages="kvkkApprovedErrors()"
									v-model="$v.contactForm.kvkkApproved.$model"
								>
									<template v-slot:label>
										<div class="caption">
											<span class="caption font-weight-bold">
												{{ getSite.companyName }}
												<a :href="getSite.endUserContractFile" target="_blank" @click.stop>KVKK Aydınlatma Metnini</a>
												okuyup anladığımı, verilerimin işlenmesi, aktarılması ve kullanılmasına bu çerçevede açıkça rıza
												gösterdiğimi beyan ve kabul ederim.
											</span>
										</div>
									</template>
								</v-checkbox>
							</v-col>
							<v-col cols="12">
								<vue-recaptcha
									ref="recaptcha"
									@verify="onVerifyCaptcha"
									@expired="onExpiredCaptcha"
									:sitekey="captchaSiteKey"
									:loadRecaptchaScript="true"
								>
								</vue-recaptcha>
							</v-col>
						</v-row>
					</v-container>
					<small>* Lüttfen gerekli alanları doldurun</small>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="red darken-1" class="text-capitalize" text @click="closeDialog"> Vazgeç </v-btn>
					<v-btn
						color="green darken-1"
						class="text-capitalize"
						text
						@click="sendForm"
						:disabled="isSendDisabled"
						:loading="loading"
					>
						Gönder
					</v-btn>
				</v-card-actions>
			</v-card>
			<v-card v-else key="successMessage">
				<v-card-text class="pa-0">
					<v-container class="pa-10" d-flex flex-column align-center justify-center>
						<v-icon size="70">far fa-circle-check</v-icon>
						<div class="text-h6 mt-5">Mesajınız başarılı bir şekilde kaydedilmiştir.</div>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" class="text-capitalize" text @click="closeDialog"> Tamam </v-btn>
				</v-card-actions>
			</v-card>
		</transition>
	</v-dialog>
</template>

<script>
import contactMessageValidationMixins from '@/mixins/validations/listing/contactMessageValidationMixins';
import VueRecaptcha from 'vue-recaptcha';
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';

const defaultContactForm = {
	name: null,
	phone: null,
	email: null,
	message: null,
	token: null,
	kvkkApproved: null,
};

export default {
	name: 'ContactForm',
	mixins: [validationMixin, contactMessageValidationMixins],
	components: {
		VueRecaptcha,
	},
	data() {
		return {
			dialog: false,
			sent: false,
			loading: false,
			contactForm: { token: null, ...defaultContactForm },
			captchaValid: false,
			sitekey: '6Lf4bUEdAAAAAEhSyB4thntPD6IsW2xE5d_ewDzx',
		};
	},
	computed: {
		...mapGetters(['getSite']),
		isSent() {
			return this.sent;
		},
		captchaSiteKey() {
			return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
		},
		isSendDisabled() {
			return this.$v.$invalid;
		},
	},
	methods: {
		onExpiredCaptcha() {
			this.captchaValid = false;
			this.$refs.recaptcha.reset();
		},
		onVerifyCaptcha(recaptchaToken) {
			this.contactForm.token = recaptchaToken;
			this.captchaValid = true;
		},
		sendForm() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}

			this.loading = true;
			this.$emit('send-form', this.contactForm, (res) => {
				this.sent = res;
				this.loading = false;
			});
		},
		closeDialog() {
			this.$v.$reset();
			this.dialog = false;
			this.sent = false;
			this.contactForm = { ...defaultContactForm };
		},
	},
};
</script>
<style lang="scss">
.fade-enter-active {
	transition: opacity 2s;
}

.fade-enter,
.fade-leave-active {
	opacity: 2;
}

.get--information {
	transform: rotate(90deg);
	right: -65px;
	top: 70px;
	position: absolute;
}
</style>
